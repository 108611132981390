import React from 'react'
import iconCheckMark from '../../../shared/assets/images/icon-check-mark.svg'
import iconWarning from '../../../shared/assets/images/icon-warning.svg'
import Button from '../../../shared/components/atoms/Button/Button'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import RegisterService from '../../../shared/services/register'
import AlertMessagge from '../../molecules/alertMessagge/alertMessagge'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import AuthService from '../../../shared/services/authentication'
import {
	localStorage,
	sessionStorage,
} from '../../../shared/state/utils/storage'
import './activationContent.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ActivationContent = props => {
	const { isBrowser } = useBrowserMode()
	const {
		descripcion,
		opcionHombres,
		opcionMujeres,
		slugHombres,
		slugMujeres,
	} = props.pageContent
	const [isModalOpened, setModalOpen] = React.useState(false)
	const [values, setValues] = React.useState(null)
	const { homeLink } = useHomeLinkMode()
	const { locale } = useLocaleMode()
	const search = isBrowser && window.location.search
	const params = isBrowser && new URLSearchParams(search)
	const urlCode = isBrowser && params.get('uc')
	React.useEffect(() => {
		if (urlCode) {
			RegisterService.fullConfirmationUrl(urlCode)
				.then(res => {
					if (res.status === 200) {
						const validUrl =
							res.data.full_url.includes('http') ||
							res.data.full_url.includes('https')
								? res.data.full_url
								: `https://${res.data.full_url}`
						const fullUrl = new URL(validUrl)
						const userId = fullUrl.searchParams.get('u')
						const code = fullUrl.searchParams.get('c')
						const samples = fullUrl.searchParams.get('s')
						const gender =
							fullUrl.searchParams.get('g') &&
							fullUrl.searchParams.get('g').trim()
						const role =
							fullUrl.searchParams.get('r') &&
							fullUrl.searchParams.get('r').trim()
						const isFromBenefits = fullUrl.searchParams.get('f').trim()
						const urlValues = {
							user: userId,
							code,
							samples,
							gender,
							role,
							fromBenefits: isFromBenefits,
						}
						setValues(urlValues)
						RegisterService.confirmUser({ userId, code }).then(res => {
							// login user after confirm
							if (res.status === 200) {
								const authData = res
								AuthService.userInfo(authData.data.idToken).then(resp => {
									if (resp.status === 200) {
										const userData = resp.data
										const fullName =
											userData.roles &&
											userData.roles[0]?.nombreRol !== 'INSTITUCION'
												? `${userData.name} ${userData.lastName || ''}`
												: userData.name

										localStorage.setItem('tokenData', authData.data)
										localStorage.setItem('userName', fullName)
										localStorage.setItem('userData', userData)
										sessionStorage.removeItem('tokenData')
										sessionStorage.removeItem('userName')
										sessionStorage.removeItem('userData')
									}
								})
							}

							if (
								res.status === 200 &&
								(samples && samples !== '' && samples !== 'undefined')
							) {
								window.location.href =
									homeLink +
									(locale === 'es-CO'
										? 'muestra-gratis/confirmacion/'
										: 'pide-tu-muestra/confirmacion/')
							} else if (res.status === 400) {
								setModalOpen(true)
								RegisterService.resendCode({ username: userId }).then(res => {})
							}
						})
					} else if (res.status === 400) {
						console.log(res.userMessage)
					}
				})
				.catch(err => {
					console.log(err)
					alert(
						'Ha ocurrido un error inesperado. Inténtelo de nuevo más tarde.'
					)
				})
		}
	}, [])
	return (
		<section className='f-content'>
			{values !== null ? (
				<>
					<div className='f-content-wrapper'>
						<img src={iconCheckMark} alt='icono-de-check-mark' />
						<h1 className='f-activation-title'>
							{values.gender === 'Masculino'
								? '¡BIENVENIDO A TENA!'
								: values.gender === 'Prefiero no responder'
								? 'AHORA ERES PARTE DE LA FAMILIA TENA'
								: '¡BIENVENIDA A TENA!'}
						</h1>
						<p className='f-activation-description'>{descripcion}</p>
						{values.fromBenefits === 'true' ? (
							<button
								className='f-btn-redirect'
								onClick={() => {
									window.location.href = homeLink + 'beneficios/acumula-puntos'
								}}
							>
								Ir a beneficios
							</button>
						) : (
							<a
								href={
									values.role === null && values.gender === 'Masculino'
										? homeLink + slugHombres
										: values.role === null && values.gender === 'Femenino'
										? homeLink + slugMujeres
										: values.role === null &&
										  values.gender === 'Prefiero no responder'
										? homeLink
										: homeLink + 'cuidadores/institucion'
								}
							>
								<button className='f-btn-redirect'>
									{values.role === null && values.gender === 'Masculino'
										? opcionHombres
										: values.role === null && values.gender === 'Femenino'
										? opcionMujeres
										: values.role === null &&
										  values.gender === 'Prefiero no responder'
										? convertText('IR A TENA')
										: convertText('IR A INSTITUCIONES')}
								</button>
							</a>
						)}
					</div>
					{isModalOpened && (
						<LayoutModal>
							<AlertMessagge
								icon={<img src={iconWarning} alt='check-correct' />}
								messagge={'CÓDIGO INVÁLIDO'}
								description={
									'Tu código de activación ha vencido. Enviaremos un nuevo código a tu correo.'
								}
								type='warning'
								button={
									<Button
										type='button'
										onClickAction={() => {
											setModalOpen(false)
										}}
										classname='f-button f-button--primary'
										text={convertText('ACEPTAR')}
									/>
								}
							/>
						</LayoutModal>
					)}
				</>
			) : null}
		</section>
	)
}
export default ActivationContent
