import { graphql } from 'gatsby'
import React from 'react'
import ActivationContent from '../components/organisms/activationContent/activationContent'
import SEO from '../shared/components/seo'
import useMobileMode from '../shared/hooks/useMobileMode'
import Layout from './../shared/components/organisms/layout/layout'

const Activacion = props => {
	const { isMobile, currentPage } = useMobileMode()
	const locale = process.env.LOCALE

	// console.log("query", props.data.allContentfulTenaRegistroActivacionCuenta);
	const content = props.data.allContentfulTenaRegistroActivacionCuenta.nodes.filter(
		node => node.node_locale.includes(locale)
	)[0]
	// const content = props.data.allContentfulTenaRegistroActivacionCuenta.nodes[0];

	return (
		<Layout title='Activación de Cuenta' isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title='Activación de Cuenta'
				description='Activa tu cuenta TENA'
				lang={props.pageContext.language}
			/>
			<ActivationContent pageContent={content} />
		</Layout>
	)
}

export const query = graphql`
	query ACTIVATION_ACCOUNT {
		allContentfulTenaRegistroActivacionCuenta {
			nodes {
				node_locale
				descripcion
				opcionHombres
				opcionMujeres
				slugHombres
				slugMujeres
			}
		}
	}
`

export default Activacion
